<template>
  <main-navigation></main-navigation>
  <router-view/>
  <footer-sticky></footer-sticky>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const currentVersion = ref<string | null>(null);
const checkInterval = 60000; // Intervall für die Prüfungen, z.B. alle 60 Sekunden

// Funktion zur Prüfung auf neue Version
async function checkForUpdates() {
  try {
    const response = await fetch('/version.json', { cache: 'no-store' }); // Versionsdatei ohne Cache anfragen
    const { version } = await response.json();

    // Falls die aktuelle Version gesetzt ist und sich die Version geändert hat, Seite neu laden
    if (currentVersion.value && currentVersion.value !== version) {
      window.location.reload();
    }
    // Aktuelle Version speichern
    currentVersion.value = version;
  } catch (error) {
    console.error("Fehler beim Laden der Version:", error);
  }
}

// Periodische Prüfung nach Updates starten
onMounted(() => {
  checkForUpdates(); // Direkt beim Start prüfen
  setInterval(checkForUpdates, checkInterval); // Weitere Prüfungen in Intervallen
});
</script>

<style>
/* Optional: Style-Angaben können hier hinzugefügt werden */
</style>
