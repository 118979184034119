<template>
  <div class="manual-checkin">
    <router-link to="/" class="close-button">✕</router-link>
    <h1>Manuelles Einstempeln</h1>

    <!-- Ladezustand -->
    <div v-if="loading" class="loading">
      Daten werden geladen...
    </div>

    <!-- Fehlermeldung -->
    <div v-if="error" class="error">
      {{ error }}
    </div>

    <!-- Liste der Mitarbeiter -->
    <div v-if="!loading && !error && !notificationMessage" class="employee-list">
      <div
          v-for="employee in employees"
          :key="employee.id"
          class="employee-tile"
          @click="selectEmployee(employee)"
      >
        {{ employee.name }}
      </div>
    </div>

    <!-- Kommen/Gehen Kachel -->
    <div v-if="selectedEmployee && !notificationMessage" class="action-tile">
      <h2>{{ selectedEmployee.name }}</h2>
      <div class="action-buttons">
        <button @click="sendCheckIn('kommen')">Kommen</button>
        <button @click="sendCheckIn('gehen')">Gehen</button>
      </div>
    </div>

    <!-- Benachrichtigungs-Box -->
    <div v-if="notificationMessage" class="notification">
      {{ notificationMessage }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      employees: [], // Liste der Mitarbeiter
      selectedEmployee: null, // Ausgewählter Mitarbeiter
      loading: true, // Ladezustand
      error: null, // Fehlernachricht
      notificationMessage: null, // Nachricht für die Benachrichtigung
    };
  },
  methods: {
    // Lade die Liste der Mitarbeiter per AJAX
    async fetchEmployees() {
      try {
        const response = await fetch(`${process.env.VUE_APP_CVCI_API_URL}/employees`);
        if (!response.ok) {
          throw new Error('Fehler beim Laden der Mitarbeiterdaten');
        }
        this.employees = await response.json();
        this.loading = false;
      } catch (err) {
        this.error = err.message;
        this.loading = false;
      }
    },
    // Auswahl eines Mitarbeiters
    selectEmployee(employee) {
      this.selectedEmployee = employee;
    },
    // "Kommen" oder "Gehen" senden
    async sendCheckIn(action) {
      try {
        const response = await fetch(`${process.env.VUE_APP_CVCI_API_URL}/time-tracking-manual`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            employeeId: this.selectedEmployee.id,
            action,
          }),
        });
        const result = await response.json();
        if (!response.ok) {
          throw new Error(result.error || 'Fehler beim Senden der Daten');
        }

        // Erfolgsmeldung anzeigen
        this.showMessage(result.message);

        // Nach 3 Sekunden zur Startseite weiterleiten
        setTimeout(() => {
          this.$router.push('/');
        }, 3000);
      } catch (err) {
        this.showMessage(`Fehler: ${err.message}`);
      }
    },

    showMessage(message) {
      // Setze die Nachricht in einen state, um sie im Template anzuzeigen
      this.notificationMessage = message;
    }
  },
  mounted() {
    // Lade die Mitarbeiterliste beim Laden der Komponente
    this.fetchEmployees();
  },
};
</script>

<style scoped>
.manual-checkin {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.loading,
.error {
  font-size: 1.2rem;
  color: #333;
  margin: 1rem;
}

.employee-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.employee-tile {
  background-color: #007bff;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  width: calc(45% - 1rem);
}

.employee-tile:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.action-tile {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 2rem;
  border-radius: 8px;
  margin-top: 2rem;
  text-align: center;
}

.action-buttons button {
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  color: white;
}

.action-buttons button:first-of-type {
  background-color: #28a745; /* Grün für Kommen */
}

.action-buttons button:last-of-type {
  background-color: #dc3545; /* Rot für Gehen */
}

.action-buttons button:hover {
  opacity: 0.8;
}

.notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #007bff;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.2rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Damit die Nachricht über anderen Inhalten liegt */
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: #333;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000; /* Über anderen Elementen positionieren */
}

.close-button:hover {
  color: #007bff; /* Optionale Hover-Farbe */
  transform: scale(1.1); /* Optionale Vergrößerung bei Hover */
}

</style>
