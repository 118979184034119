import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MainNavigation from "@/components/MainNavigation.vue";
import ManualCheckIn from "@/components/ManualCheckIn.vue";
import AttendanceList from "@/components/AttendanceList.vue";
import NewCard from "@/components/NewCard.vue";
import Help from "@/components/Help.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/menu',
        name: 'menu',
        component: MainNavigation
    },
    {path: '/manual', name: 'ManualCheckIn', component: ManualCheckIn},
    {path: '/attendance', name: 'AttendanceList', component: AttendanceList},
    {path: '/new-card', name: 'NewCard', component: NewCard},
    {path: '/help', name: 'Help', component: Help},
    // {
    //     path: '/about',
    //     name: 'about',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/AboutView.vue')
    // },
    // {
    //     path: '/validation',
    //     name: 'validation',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/ValidationView.vue')
    // },
    // {
    //     path: '/xRechnungCreate',
    //     name: 'xRechnungCreate',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/xRechnungCreateView.vue')
    // },
    // {
    //     path: '/xRechnungCreate/verkaeufer',
    //     name: 'xRechnungCreateSeller',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/xRechnungCreateSellerView.vue')
    // },
    // {
    //     path: '/xRechnungCreate/kaeufer',
    //     name: 'xRechnungCreateBuyer',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/xRechnungCreateBuyerView.vue')
    // },
    // {
    //     path: '/xRechnungCreate/posten',
    //     name: 'xRechnungCreateItems',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/xRechnungCreateItemsView.vue')
    // },
    // {
    //     path: '/price',
    //     name: 'price',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/PriceView.vue')
    // },
    // {
    //     path: '/ansehen',
    //     name: 'viewer',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/PriceView.vue')
    // },
    // {
    //     path: '/faq',
    //     name: 'faq',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/FAQView.vue')
    // },
    // {
    //     path: '/imprint',
    //     name: 'imprint',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/ImprintView.vue')
    // },
    // {
    //     path: '/imprint2',
    //     name: 'imprint2',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/ImprintView.vue')
    // },
    // {
    //     path: '/nfc',
    //     name: 'nfc',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import( '../views/NfcView.vue')
    // }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
