<template>
  <div ref="fullscreenContainer" class="fullscreen-container">
    <!-- Button zum Aktivieren des Vollbildmodus -->
    <button v-if="!isFullscreen" @click="enterFullscreen">
      Vollbildmodus starten
    </button>

    <!-- Button zum Starten des NFC-Scans -->
    <button v-if="nfcAvailable && !nfcScanning" @click="startNfc">Zeiterfassung Starten</button>

    <!-- Nachricht, wenn NFC nicht verfügbar ist und manuelle Eingabe erforderlich ist -->
    <div v-if="!nfcAvailable && !showApiMessage" class="nfc-scanning">
      <router-link to="/menu">
        <img src="/nfcImg.webp" alt="NFC-Bild" class="nfc-image">
      </router-link>
      <div class="nfc-text">
        <p>Seriennummer manuell eingeben:</p>
        <label for="serialNumber">Seriennummer:</label>
        <input type="text" v-model="manualSerialNumber" id="serialNumber" placeholder="Seriennummer eingeben"/>
        <button @click="sendManualSerialToApi">Absenden</button>
      </div>
    </div>

    <!-- Nachricht während des NFC-Scans -->
    <div v-if="nfcScanning && !showApiMessage" class="nfc-scanning">
      <router-link to="/menu">
        <img src="/nfcImg.webp" alt="NFC-Bild" class="nfc-image">
      </router-link>
      <p class="nfc-text">Halte dein NFC-Tag hinter das Gerät</p>
    </div>

    <!-- Rückmeldung von der API neben dem Bild -->
    <div v-if="showApiMessage" class="nfc-scanning">
      <router-link to="/menu">
        <img src="/nfcImg.webp" alt="NFC-Bild" class="nfc-image">
      </router-link>
      >
      <div class="api-message">
        <p>{{ apiMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
/* global NDEFReader */
export default {
  data() {
    return {
      nfcContent: null,
      nfcAvailable: true,
      nfcScanning: false,
      manualSerialNumber: '',
      apiMessage: null,
      isFullscreen: false, // Vollbildmodus-Status
      showApiMessage: false // Status für die Anzeige der API-Rückmeldung
    };
  },
  methods: {
    async startNfc() {
      try {
        if ('NDEFReader' in window) {
          this.nfcScanning = true;
          const ndef = new NDEFReader();

          await ndef.scan();

          ndef.onreading = async event => {
            const serialNumber = event.serialNumber || 'unknown';
            this.nfcContent = `Seriennummer: ${serialNumber}`;

            // Seriennummer per Funktion senden
            await this.sendSerialToApi(serialNumber);
          };
        } else {
          this.nfcAvailable = false;
        }
      } catch (error) {
        console.error('Error reading NFC tag:', error);
        if (error.name === 'NotAllowedError') {
          alert('Zugriff auf NFC verweigert. Stelle sicher, dass die Berechtigung gewährt wurde.');
        }
        this.nfcAvailable = false;
        this.nfcScanning = false;
      }
    },
    async sendManualSerialToApi() {
      if (!this.manualSerialNumber) {
        alert('Bitte eine Seriennummer eingeben!');
        return;
      }

      // Blende `.nfc-text` aus und zeige die API-Rückmeldung an
      this.showApiMessage = true;
      await this.sendSerialToApi(this.manualSerialNumber);
      this.manualSerialNumber = '';
      this.nfcScanning = false;
    },
    async sendSerialToApi(serialNumber) {
      try {
        const response = await fetch(`${process.env.VUE_APP_CVCI_API_URL}/time-tracking`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({serialNumber})
        });

        const result = await response.json();
        if (!response.ok) {
          throw new Error(result.error || 'Fehler beim Senden der Seriennummer an die API');
        }

        this.showApiMessage = true;
        this.showApiMessageText(result.message);
      } catch (error) {
        this.showApiMessageText(error.message);
      } finally {
        this.nfcScanning = true;
        this.enterFullscreen();
      }
    },
    showApiMessageText(message) {
      this.apiMessage = message;
      this.showApiMessage = true; // API-Rückmeldung anzeigen
      setTimeout(() => {
        this.apiMessage = null;
        this.showApiMessage = false; // Blende die API-Rückmeldung wieder aus
      }, 5000); // Nachricht für 5 Sekunden anzeigen
    },
    enterFullscreen() {
      const elem = this.$refs.fullscreenContainer;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { // Safari
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { // IE11
        elem.msRequestFullscreen();
      }
    },
    updateFullscreenStatus() {
      this.isFullscreen = !!document.fullscreenElement;
    }
  },
  mounted() {
    if (!('NDEFReader' in window)) {
      this.nfcAvailable = false;
    }
    document.addEventListener("fullscreenchange", this.updateFullscreenStatus);
    this.startNfc();
  },
  beforeUnmount() {
    document.removeEventListener("fullscreenchange", this.updateFullscreenStatus);
  }
};
</script>

<style scoped>
.fullscreen-container {
  /* Hintergrundfarbe im Vollbildmodus */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh; /* stellt sicher, dass der Vollbildmodus das gesamte Fenster einnimmt */
}

.nfc-scanning {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem; /* Abstand zwischen Bild und Text */
  padding: 1rem;
}

.nfc-image {
  height: auto;
  max-height: 100%; /* Begrenzung der Bildhöhe */
  width: auto;
  max-width: 50%;
}

.nfc-text {
  font-size: 1.5rem;
  color: #333;
  text-align: left;
  max-width: 50%;
}

input, button {
  margin-top: 0.5rem;
  display: block;
}

.api-message {
  text-align: left;
  padding: 10px;
  border-radius: 5px;
  color: #333;
}
</style>
