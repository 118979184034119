<template>
  <router-link to="/" class="close-button">✕</router-link>
  <div class="menu-container">
    <!-- Kacheln -->
    <div class="tile" @click="navigateTo('/manual')">
      Manuell Einstempeln
    </div>
    <div class="tile" @click="navigateTo('/attendance')">
      Anwesenheitsliste
    </div>
    <div class="tile" @click="navigateTo('/new-card')">
      Neue Karte
    </div>
    <div class="tile" @click="navigateTo('/help')">
      Hilfe
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    navigateTo(route) {
      this.$router.push(route); // Navigation zur gewünschten Route
    }
  }
};
</script>

<style scoped>
.menu-container {
  display: flex;
  flex-wrap: wrap; /* Flexbox, um Elemente bei Bedarf umzubrechen */
  justify-content: space-around; /* Gleichmäßiger Abstand zwischen Kacheln */
  align-items: center;
  width: 100%;
  height: 100vh; /* Vollbild für das Menü */
  background-color: #f5f5f5; /* Heller Hintergrund */
  padding: 1rem;
  box-sizing: border-box;
}

.tile {
  flex: 1 1 calc(45% - 1rem); /* Jede Kachel nimmt etwa 45% Breite, mit etwas Abstand */
  height: calc(40% - 1rem); /* Höhe der Kacheln passt sich dynamisch an */
  margin: 0.5rem; /* Abstand zwischen den Kacheln */
  display: flex;
  justify-content: center; /* Zentriert den Text horizontal */
  align-items: center; /* Zentriert den Text vertikal */
  background-color: #007bff; /* Blaue Farbe für Kacheln */
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  border-radius: 10px; /* Abgerundete Ecken */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Leichter Schatten */
  cursor: pointer; /* Zeigt eine Hand beim Hover */
  transition: transform 0.2s, box-shadow 0.2s;
}

.tile:hover {
  transform: scale(1.05); /* Leichte Vergrößerung beim Hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: #333;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000; /* Über anderen Elementen positionieren */
}

.close-button:hover {
  color: #007bff; /* Optionale Hover-Farbe */
  transform: scale(1.1); /* Optionale Vergrößerung bei Hover */
}

</style>
