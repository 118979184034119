<template>
  <router-link to="/" class="close-button">✕</router-link>
  <div>
    <h1>Anwesend sind</h1>
    <p>Hier können Benutzer ihre Zeit manuell erfassen.</p>
  </div>
</template>
<script>
export default {
  name: 'ManualCheckIn'
};
</script>
<style scoped>
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  color: #333;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000; /* Über anderen Elementen positionieren */
}

.close-button:hover {
  color: #007bff; /* Optionale Hover-Farbe */
  transform: scale(1.1); /* Optionale Vergrößerung bei Hover */
}

</style>